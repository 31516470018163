<!-- 收货验收 -->
<template>
  <div class="page">
    <div v-if="!disblesImg" class="top">
      <Form class="formMarginBtm20" inline>
        <!-- 收货仓库 -->
        <FormItem>
          <span class="label">收货仓库：</span>
          <Select id="sstj1" class="iviewIptWidth250 marginRight60" v-model="queryFrom.warehouse_id" filterable clearable>
            <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id + item.warehouse_name">{{ item.warehouse_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>供应商：</span>
          <Select id="sstj2" class="iviewIptWidth250 marginRight60" clearable filterable placeholder="请选择" ref="name_select" v-model="queryFrom.supplier_id" @on-change="supplierChange">
            <Option :value="item.supplier_id" v-for="item in supplierList" :key="item.supplier_id">{{ item.supplier_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>产品名称：</span>
          <Select id="sstj3" class="iviewIptWidth250 marginRight60" @on-change="productChange" clearable filterable placeholder="请选择" :disabled="!queryFrom.supplier_id" ref="name_select" v-model="queryFrom.product_id">
            <Option v-for="item in productList" :value="item.product_id" :key="item.supplier_product_id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>规格型号/SKU：</span>
          <Select id="sstj4" class="iviewIptWidth250 marginRight60" :disabled="!queryFrom.supplier_id || !queryFrom.product_id" multiple filterable clearable v-model="queryFrom.code_id" :max-tag-count="queryFrom.code_id.length == 1 ? 1 : 0">
            <Option v-for="item in modelList" :value="item.code_id" :key="item.code_id + item.product_id" :label="item.model_name + '-' + item.item_number">
              <span>{{ item.model_name }}</span>
              <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
            </Option>
          </Select>
        </FormItem>
        <FormItem :label-width="0" class="button">
          <!--          <span class="pageBtn finger btnSure" @click="query">查询</span>-->
          <span id="glcgd_qr" class="longTextBtn finger btnAdd" @click="openModal(1)">关联采购单</span>
          <!--          <span class="longTextBtn finger btnReset marginLeft20" @click="openModal(2)">关联发货单</span>-->
          <span id="xsthtzd_qr" class="longTextBtn finger btnReset marginLeft20" @click="openModal(3)">销售退货通知单</span>
        </FormItem>
      </Form>
    </div>
    <div v-if="!disblesImg" class="btm">
      <div class="barcode">
        <p><span class="fl">条形码</span> <span id="txm_search" class="pageBtn finger btnSure fr" @click="queryCode">查询</span></p>
        <Input id="txm_input" class="textarea" placeholder="请输入带入库条形码，多个产品换行显示" type="textarea" v-model="codeStr"></Input>
      </div>
      <div class="btmRight">
        <div class="checkList">
          <p>
            产品清单<span style="margin-left: 20px;">{{ supplier_name }}</span>
          </p>
          <Table :columns="checkColumns" :data="checkData" border></Table>
          <div v-if="checkData.length == 0" class="summary picking">暂未选择采购单/销售退货通知单</div>
        </div>
        <p>待收货明细清单<span id="sz" class="fr pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span></p>
        <tableCom :productList="listColumns" :height="listData.length == 0 ? 0 : 500" :productData="listData" :option_page="option_page" border>
          <template slot-scope="{ row, index }" slot="bar_code">
            <i-input placeholder="" :value="row.bar_code" @on-change="changeIpt($event, 'bar_code', index)" readonly></i-input>
          </template>
          <template slot-scope="{ row, index }" slot="batch_number">
            <i-input placeholder="请输入" :value="row.batch_number" @on-change="changeIpt($event, 'batch_number', index)"></i-input>
          </template>
          <template slot-scope="{ row, index }" slot="serial_number">
            <i-input placeholder="请输入" :value="row.serial_number" @on-change="changeIpt($event, 'serial_number', index)"></i-input>
          </template>
          <template slot-scope="{ row, index }" slot="quantity">
            <!--            <i-input placeholder="请输入" type="number" :value="row.quantity" @on-change="changeIpt1($event, 'quantity', index)"></i-input>-->
            <InputNumber :max="999999" :min="0" :precision="0" :disabled="row.isCode ? row.isCode : false" v-model="row.quantity" :active-change="false" @on-change="changeIpt1($event, 'quantity', index, row.model_name, row.product_name, row.product_code_id, row.id)"></InputNumber>
          </template>
          <template slot-scope="{ row, index }" slot="validity">
            <Input @on-blur="dataBlur($event)" @on-change="changeIpt($event, 'valid_period', index)" type="text" :value="row.valid_period" placeholder="请输入" style="width: 200px;" />
            <!-- <DatePicker :editable="true" :transfer="true" placement="bottom-start" placeholder="请选择" type="date" format="yyyy-MM-dd" :value="row.valid_period" @on-change="timeChange($event, index, 'valid_period')"></DatePicker> -->
          </template>
          <template slot-scope="{ row, index }" slot="productionDate">
            <Input @on-blur="dataBlur($event, true)" @on-change="changeIpt($event, 'production_date', index)" type="text" :value="row.production_date" placeholder="请输入" style="width: 200px;" />
            <!-- <DatePicker :transfer="true" :editable="true" placeholder="请选择" placement="bottom-start" :value="row.production_date" type="date" format="yyyy-MM-dd" @on-change="timeChange($event, index, 'production_date')"></DatePicker> -->
          </template>
          <template slot-scope="{ row, index }" slot="do">
            <div @click="deleteRow(row, index)"><span :id="'dshmxqd_qr' + index" class="finger color389">删除</span></div>
          </template>
        </tableCom>
        <!-- <div style="position: relative; border: 1px solid #e8eaec; height: 450px; border-top: none !important;" class="clearfix noData" v-if="listData.length == 0">
          <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
        </div> -->
        <div class="totalNumber">
          <div class="item">
            总数量:<span class="color389 marginRight20">{{ TotalQuantity }}</span>
          </div>
          <div class="item">
            总金额:<span class="color389 marginRight20">￥ {{ TotalMoney }}</span>
          </div>
        </div>
        <div class="button">
          <span id="qrsh" class="longTextBtn finger btnSure marginLeft20" @click="surePost" v-if="!insertAble">确认收货</span>
        </div>
      </div>
    </div>
    <div class="disableImage" v-if="disblesImg">
      <div class="disableDiv">
        <img src="../../assets/images/disableImg.png" alt="" />
        <span>当月已关账，不可操作库存</span>
      </div>
    </div>
    <!--    弹窗选择数据-->
    <Modal v-model="chooseStatus" label-position="left" width="1200" :footer-hide="true" class-name="choose vertical-center-modal" title="产品列表">
      <div v-if="chooseStatus">
        <Table :columns="chooseColumns" :data="chooseList" border @on-selection-change="selectChange" height="500"></Table>
        <div class="clearfix" style="margin-top: 20px;">
          <span id="tcxz_qr" class="pageBtn finger btnSure fr" @click="sureChoose">确定</span>
        </div>
      </div>
    </Modal>
    <!-- 采购单 -->
    <Modal id="glcgd" @on-cancel="cancelQueryModal1()" :mask-closable="false" class="modal" v-model="purchase" width="80">
      <p class="header" slot="header">采购单</p>
      <div class="body">
        <Form class="formMarginBtm20" inline>
          <FormItem>
            <span>订单号：</span>
            <i-input id="glcgd_sstj1" placeholder="请输入" class="iviewIptWidth200 marginRight23" v-model="queryModal1.order_number"></i-input>
          </FormItem>
          <FormItem>
            <span>下单时间：</span>
            <DatePicker id="glcgd_sstj2" :editable="false" class="iviewIptWidth200 marginRight23" format="yyyy-MM-dd" placeholder="请选择" placement="bottom-end" type="daterange" @on-change="changeTime($event, 1)"></DatePicker>
          </FormItem>
          <FormItem>
            <span>供应商：</span>
            <Select id="glcgd_sstj3" class="iviewIptWidth200 marginRight23" clearable filterable placeholder="请选择" ref="name_select" v-model="queryModal1.supplier_id" @on-change="supplierChange">
              <Option :key="index" :value="item.supplier_id" v-for="(item, index) in supplierList">{{ item.supplier_name }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>订单状态：</span>
            <Select id="glcgd_sstj4" class="iviewIptWidth200 marginRight23" clearable filterable placeholder="请选择" ref="name_select" v-model="queryModal1.purchase_order_state" @on-change="supplierChange">
              <Option :key="index" :value="item.id" v-for="(item, index) in odererStateList">{{ item.purchase_order_state }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>产品编码：</span>
            <i-input placeholder="请输入" class="iviewIptWidth200 marginRight23" v-model="queryModal1.product_model_code"></i-input>
          </FormItem>
          <FormItem>
            <span>产品名称：</span>
            <!-- <Select class="iviewIptWidth200" clearable filterable placeholder="请选择" v-model="queryModal1.product_name">
              <Option :key="index" :value="item.name" v-for="(item, index) in productListSeclect">{{ item.name }}</Option>
            </Select> -->
            <Select class="iviewIptWidth200 marginRight23" clearable filterable placeholder="请选择" v-model="queryModal1.product_name" @on-change="productChange1">
              <Option :key="index" :value="item.product_name" v-for="(item, index) in productListSeclect">{{ item.product_name }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>规格型号/SKU：</span>
            <Select class="iviewIptWidth200" multiple filterable clearable v-model="queryModal1.model_name" :max-tag-count="queryModal1.model_name.length == 1 ? 1 : 0">
              <Option v-for="(item, index) in modelList" :value="item.model_name" :key="index" :label="item.model_name">
                <span>{{ item.model_name }}</span>
                <!-- <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.product_model_code }}</span> -->
              </Option>
            </Select>
          </FormItem>
          <FormItem :label-width="10" class="button">
            <span id="glcgd_cx" class="pageBtn finger btnSure" @click="queryData(1)">查询</span>
          </FormItem>
        </Form>
        <table-com :productData="purchaseData" :productList="purchaseColumns" class="table" :pages="queryModal1" :total="total" totalText="条记录" @change-page="changePage($event, 1)">
          <template slot-scope="{ row, index }" slot="product_list">
            <Poptip trigger="hover" :content="row.product_list">
              <span>{{ row.product_list.split(',').splice(0, 2).join(',') }}</span>
            </Poptip>
          </template>
        </table-com>
      </div>
      <div slot="footer"></div>
    </Modal>
    <!-- 退货通知单 -->
    <Modal id="xsthtzd" @on-cancel="cancelQueryModal3()" :mask-closable="false" v-model="salesReturn" class="modal" width="80">
      <p class="header" slot="header">退货通知单</p>
      <div>
        <Form class="formMarginBtm20" inline>
          <FormItem>
            <span>通知单号：</span>
            <i-input id="xsthtzd_sstj1" placeholder="请输入" class="iviewIptWidth200 marginRight23" v-model="queryModal3.return_code"></i-input>
          </FormItem>
          <FormItem>
            <span>下单时间：</span>
            <DatePicker id="xsthtzd_sstj2" :editable="false" class="iviewIptWidth200 marginRight23" filterable placeholder="请选择" placement="bottom-end" type="daterange" @on-change="changeTime($event, 3)"></DatePicker>
          </FormItem>
          <FormItem>
            <span>客户名称：</span>
            <Select id="xsthtzd_sstj3" class="iviewIptWidth200 marginRight23" filterable v-model="clientIndex" clearable>
              <Option v-for="(item, index) in clientNameList" :value="index" :key="item.customer_id + item.customer_type">{{ item.customer_name }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>产品编码：</span>
            <i-input placeholder="请输入" class="iviewIptWidth200" v-model="queryModal3.product_model_code"></i-input>
          </FormItem>
          <FormItem>
            <span>产品名称：</span>
            <!-- <Select class="iviewIptWidth200" clearable filterable placeholder="请选择" v-model="queryModal3.product_name">
              <Option :key="index" :value="item.name" v-for="(item, index) in productListSeclect">{{ item.name }}</Option>
            </Select> -->
            <Select class="iviewIptWidth200 marginRight23" clearable filterable placeholder="请选择" v-model="queeryModal3.product_name" @on-change="productChange3">
              <Option :key="index" :value="item.product_name" v-for="(item, index) in productListSeclect">{{ item.product_name }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>规格型号/SKU：</span>
            <Select class="iviewIptWidth200" multiple filterable clearable v-model="queryModal3.model_name" :max-tag-count="queryModal3.model_name.length == 1 ? 1 : 0">
              <Option v-for="(item, index) in modelList" :value="item.model_name" :key="index" :label="item.model_name">
                <span>{{ item.model_name }}</span>
                <!-- <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.product_model_code }}</span> -->
              </Option>
            </Select>
          </FormItem>
          <FormItem :label-width="10" class="button">
            <span id="xsthtzd_sstj4" class="pageBtn finger btnSure" @click="queryData(3)">查询</span>
          </FormItem>
        </Form>
        <table-com :productData="salesReturnData" :productList="salesReturnColumns" class="table" :pages="queryModal3" :total="total" totalText="条记录" @change-page="changePage($event, 3)">
          <template slot-scope="{ row, index }" slot="product_name_list">
            <Poptip trigger="hover" :content="row.product_name_list">
              <span>{{ row.product_name_list.split(',').splice(0, 2).join(',') }}</span>
            </Poptip>
          </template>
        </table-com>
      </div>
      <div slot="footer"></div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="lastPost"></tips-component>
    <!--    审核-->
    <Modal v-model="modal1" label-position="left" width="400" :footer-hide="true" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>收货数量小于订单数量，是否确认收货</p>
        <div>
          <span id="sh_qr" class="pageBtn finger btnSure" @click="lastPost">确定</span>
          <span id="sh_qx" class="pageBtn finger btnCancle" @click="modal1 = false">取消</span>
        </div>
      </div>
    </Modal>

    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import TableSetup from '@/components/tableSetup'
import tableCom from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
import { mapState } from 'vuex'
import { dataType } from '@/service/utils'
export default {
  name: 'receivingAcceptance',
  components: {
    tableCom,
    TipsComponent,
    TableSetup,
  },
  computed: {
    ...mapState(['clientNameList']),
    //  总数量
    TotalQuantity() {
      let total = 0
      this.listData.forEach(item => {
        total = total + item.quantity
      })
      return total
    },
    // 总金额
    TotalMoney() {
      let total = 0
      this.listData.forEach(item => {
        total = total + item.unit_price * item.quantity
      })
      return total.toFixed(2)
    },
  },
  inject: ['reload'],
  data() {
    return {
      productListSeclect: [],
      disblesImg: false,
      odererStateList: [
        { id: 1, purchase_order_state: '待确认' },
        { id: 2, purchase_order_state: '待收货' },
        { id: 8, purchase_order_state: '待发货' },
        { id: 9, purchase_order_state: '部分收货' },
      ],
      // type=1 是关联采购单 type=2是退货通知
      type: 0,
      titleList: [],
      option_page: '12',
      setupStatus: false,
      modal1: false,
      checkColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 200,
        },
        {
          title: '订单数量',
          key: 'quantity',
          align: 'center',
          width: 150,
        },
        {
          title: '收货数量',
          key: 'pickNum',
          align: 'center',
          width: 150,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 150,
        },
        {
          title: '操作',
          align: 'center',
          width: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                  },
                  class: 'cpqd_ysh' + param.index,
                  on: {
                    click: () => {
                      this.readyRow(param.row, param.index)
                    },
                  },
                },
                '预收货'
              ),
            ])
          },
        },
      ],
      checkData: [],
      clientIndex: -1,
      postStatus: false,
      total: 0,
      chooseStatus: false,
      chooseList: [],
      chooseColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 55,
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          width: 150,
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 150,
        },
        {
          title: '订单数量',
          key: 'number',
          align: 'center',
          width: 100,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 70,
        },
        {
          title: '单价',
          align: 'center',
          width: 90,
          render: (h, param) => {
            return h('div', [h('span', param.row.unit_price ? '¥' + param.row.unit_price : 0)])
          },
        },
        {
          title: '厂家名称',
          key: 'factory_name',
          align: 'center',
        },
      ],
      modelList: [],
      storeList: [],
      supplierList: [],
      productList: [],
      // 销售退货通知单
      salesReturn: false,
      salesReturnColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '下单时间',
          key: 'returnTime',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '通知单号',
          key: 'return_code',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '产品',
          slot: 'product_name_list',
          align: 'left',
          minWidth: 180,
        },
        {
          title: '数量',
          key: 'return_amount',
          align: 'center',
          minWidth: 100,
          maxWidth: 130,
        },
        {
          title: '金额',
          key: 'return_price',
          align: 'center',
          minWidth: 100,
          maxWidth: 130,
          render: (h, param) => {
            return h('span', '¥' + param.row.return_price)
          },
        },
        {
          title: '客户名称',
          key: 'customer_name',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '操作',
          align: 'center',
          width: 128,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  class: 'thtzd_qr' + param.index,
                  on: {
                    click: () => {
                      this.saleReturnSure(param.row)
                    },
                  },
                },
                '确认'
              ),
            ])
          },
        },
      ],
      salesReturnData: [],
      // 关联采购单
      purchase: false,
      purchaseColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '下单时间',
          key: 'insertTime',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '订单号',
          key: 'order_number',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '产品',
          slot: 'product_list',
          align: 'left',
          minWidth: 180,
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          minWidth: 100,
          maxWidth: 130,
        },
        {
          title: '金额',
          key: 'total_amount',
          align: 'center',
          minWidth: 100,
          maxWidth: 130,
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.total_amount, true))
          },
        },
        {
          title: '供应商',
          key: 'supplier_name',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '备注',
          key: 'note',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '订单状态',
          key: 'purchase_order_state_str',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '操作',
          align: 'center',
          width: 128,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                  },
                  class: 'glcgd_qr' + param.index,
                  on: {
                    click: () => {
                      this.choosePurchase(param.row)
                    },
                  },
                },
                '确认'
              ),
            ])
          },
        },
      ],
      purchaseData: [],
      // 全部已选数据
      listData: [],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
          fixed: 'left',
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
          width: 281,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '条形码',
          slot: 'bar_code',
          align: 'center',
          width: 350,
        },
        {
          title: '批号',
          slot: 'batch_number',
          align: 'center',
          width: 150,
        },
        {
          title: '序列号',
          slot: 'serial_number',
          align: 'center',
          width: 150,
        },
        {
          title: '数量',
          slot: 'quantity',
          align: 'center',
          width: 118,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 67,
        },
        {
          title: '采购单价',
          key: 'unit_price',
          align: 'center',
          width: 118,
        },
        {
          title: '生产日期',
          slot: 'productionDate',
          align: 'center',
          width: 150,
        },
        {
          title: '有效期',
          slot: 'validity',
          align: 'center',
          width: 150,
        },
        {
          title: '操作',
          align: 'center',
          width: 100,
          fixed: 'right',
          render: (h, param) => {
            return h('div', [
              // h(
              //   'span',
              //   {
              //     style: {
              //       color: '#389AFC',
              //
              //       cursor: 'pointer',
              //       marginRight: '20px'
              //     },
              //     class:{
              //       disableUse:this.insertAble
              //     },
              //     on: {
              //       click: () => {
              //         this.addRow(param.row, param.index)
              //       },
              //     },
              //   },
              //   '新增'
              // ),
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  class: {
                    disableUse: this.deleteAble,
                  },
                  class: 'dshmxd_sc' + param.index,
                  on: {
                    click: () => {
                      this.deleteRow(param.row, param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      // 表单拉下选项
      // 查询参数
      queryFrom: {
        warehouse_id: '',
        supplier_id: '',
        product_id: '',
        code_id: [],
      },
      queryModal1: {
        product_model_code: '',
        product_name: '',
        page: 1,
        rows: 10,
        order_number: '',
        supplier_id: '',
        insert_time_start: '',
        insert_time_end: '',
        picking_page: '1',
        model_name:''
      },
      queryModal3: {
        product_model_code: '',
        product_name: '',
        page: 1,
        rows: 10,
        return_code: '',
        return_time_begin: '',
        return_time_end: '',
        customer_id: '',
        customer_type: '',
        related_accetance_order: '1',
        picking_page: '1',
        model_name:''
      },
      deleteIndex: 0,
      deleteObj: {},
      deleteStatus: false,
      postFrom: {
        warehouse_id: '',
        supplier_id: '',
        line_info: [],
      },
      hadSelectList: [],
      codeStr: '',
      supplier_name: '',
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
      valid_period: '',
      flag: true,
    }
  },
  mounted() {
    this.queryOption()
    this.querySupplierSelect()
    this.queryStoreList()
    this.getRightVisibal()
    this.inventoryLook()
    this.queryProduct()
  },
  activated() {
    this.inventoryLook()
  },
  methods: {
    queryProduct(id = null, subId = null) {
      // this.$http.get(this.$api.getProductNameSelect, { product_name: subId, warehouse_id: this.warehouse_id }, false).then(res => {
      //   this.productListSeclect = res.data
      // })
      this.$http.get(this.$api.productShow, {search_type:'1'}, false).then(res => {
        console.log(res);
        this.productListSeclect = res.data
      })
    },
    // 新增界面禁用功能
    inventoryLook() {
      this.$http.get(this.$api.inventoryLook, {}).then(res => {
        if (res.data === 1) {
          this.disblesImg = true
        }
      })
    },
    dataBlur(e, type) {
      if (!e.target.value && type) return
      let res = this.$utils.dataType(e.target.value)
      if (res.code) {
        this.$Message.warning(res.text)
      }
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.$http
        .get(this.$api.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
              // type=1 是关联采购单 type=2是退货通知
              if (this.titleList[i].title === '条形码' && this.type === 1) {
                this.titleList[i].slot = 'bar_code'
              }
              if (this.titleList[i].title === '批号' && this.type === 1) {
                this.titleList[i].slot = 'batch_number'
              }
              if (this.titleList[i].title === '序列号' && this.type === 1) {
                this.titleList[i].slot = 'serial_number'
              }
              if (this.titleList[i].title === '数量' && this.type === 1) {
                this.titleList[i].slot = 'quantity'
              }
              if (this.titleList[i].title === '生产日期' && this.type === 1) {
                this.titleList[i].slot = 'productionDate'
              }
              if (this.titleList[i].title === '有效期' && this.type === 1) {
                this.titleList[i].slot = 'validity'
              }

              // 退货通知处理
              if (this.titleList[i].title === '条形码' && this.type === 2) {
                this.titleList[i].slot = 'bar_code'
              }
              if (this.titleList[i].title === '批号' && this.type === 2) {
                this.titleList[i].slot = 'batch_number'
              }
              if (this.titleList[i].title === '数量' && this.type === 2) {
                this.titleList[i].slot = 'quantity'
              }
              if (this.titleList[i].title === '有效期' && this.type === 2) {
                this.titleList[i].slot = 'validity'
              }
              if (this.titleList[i].title === '序列号' && this.type === 2) {
                this.titleList[i].slot = 'serial_number'
              }
              if (this.titleList[i].title === '生产日期' && this.type === 2) {
                this.titleList[i].slot = 'productionDate'
              }
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })

          let doTitle = {
            title: '操作',
            align: 'center',
            slot: 'do',
            width: 100,
            fixed: 'right',
          }
          this.listColumns = [...this.listColumns, ...this.titleList, doTitle]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .then(() => {
          // this.queryStoreList()
        })
    },
    // 预收货
    readyRow(row, index) {
      this.$set(this.checkData[index], 'pickNum', this.checkData[index].pickNum + row.quantity)
      this.$set(this.listData, this.listData.length, row)
      let length = this.listData.length - 1
      // 注册证/备案凭证有效期至
      if (this.listData[length].licence_valid_period) {
        this.listData[length].licence_valid_period = this.$moment.unix(this.listData[length].licence_valid_period).format('YYYY-MM-DD')
      }
      // 生产日期
      if (this.listData[length].production_date) {
        this.listData[length].production_date = this.$moment.unix(this.listData[length].production_date).format('YYYY-MM-DD').replace(/-/gi, '')
      }
      // 有效期
      if (this.listData[length].valid_period) {
        this.listData[length].valid_period = this.$moment.unix(this.listData[length].valid_period).format('YYYY-MM-DD').replace(/-/gi, '')
      }
      // 生产许可证有效期
      if (this.listData[length].production_license_valid_period) {
        this.listData[length].production_license_valid_period = this.$moment.unix(this.listData[length].production_license_valid_period).format('YYYY-MM-DD')
      }
      // 生产备案日期
      if (this.listData[length].production_record_date) {
        this.listData[length].production_record_date = this.$moment.unix(this.listData[length].production_record_date).format('YYYY-MM-DD')
      }
      // 经营许可证有效期
      if (this.listData[length].business_license_valid_period && this.$moment.unix(this.listData[length].business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
        this.listData[length].business_license_valid_period = this.$moment.unix(this.listData[length].business_license_valid_period).format('YYYY-MM-DD')
      } else {
        this.listData[length].business_license_valid_period = '长期'
      }
      // 经营备案日期
      if (this.listData[length].record_valid_period && this.$moment.unix(this.listData[length].record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
        this.listData[length].record_valid_period = this.$moment.unix(this.listData[length].record_valid_period).format('YYYY-MM-DD')
      } else {
        this.listData[length].record_valid_period = '长期'
      }
      // 采购/销售退货时间
      if (this.listData[length].return_time) {
        this.listData[length].return_time = this.$moment.unix(this.listData[length].return_time).format('YYYY-MM-DD')
      }
      // 收货时间
      if (this.listData[length].receive_time) {
        this.listData[length].receive_time = this.$moment.unix(this.listData[length].receive_time).format('YYYY-MM-DD')
      }
      // 验收时间
      if (this.listData[length].accept_time) {
        this.listData[length].accept_time = this.$moment.unix(this.listData[length].accept_time).format('YYYY-MM-DD')
      }
      // 入库时间
      if (this.listData[length].rec_time) {
        this.listData[length].rec_time = this.$moment.unix(this.listData[length].rec_time).format('YYYY-MM-DD')
      }
      // 销售/采购退货时间
      if (this.listData[length].order_time) {
        this.listData[length].order_time = this.$moment.unix(this.listData[length].order_time).format('YYYY-MM-DD')
      }
      // 拣货时间
      if (this.listData[length].picking_time) {
        this.listData[length].picking_time = this.$moment.unix(this.listData[length].picking_time).format('YYYY-MM-DD')
      }
      // 出库时间
      if (this.listData[length].delivery_time) {
        this.listData[length].delivery_time = this.$moment.unix(this.listData[length].delivery_time).format('YYYY-MM-DD')
      }
    },
    // 条码查询
    queryCode() {
      let nameArr = []
      if (this.checkData.length == 0) {
        this.$Message.warning('请选择采购单/销售退货通知单')
        return
      }
      this.checkData.forEach(item => {
        nameArr.push(item.product_name + item.model_name)
      })
      nameArr = [...new Set(nameArr)]
      if (!this.codeStr) {
        this.$Message.warning('请输入条形码再查询')
        return
      }
      let arr = this.codeStr.trim().split('\n')
      this.$http.post(this.$api.productSearchBarcode, { barcode_list: arr }, true).then(res => {
        this.codeStr = ''
        if (res.data.length == 0) {
          this.$Message.warning('清单中暂无匹配数据')
        } else {
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < this.checkData.length; j++) {
              if (res.data[i].name === this.checkData[j].name && res.data[i].model_name === this.checkData[j].model_name) {
                if (this.checkData[j].return_code) {
                  res.data[i].return_code = this.checkData[j].return_code
                  res.data[i].return_detail_id = this.checkData[j].return_detail_id
                } else if (this.checkData[j].purchase_order_id) {
                  res.data[i].id = this.checkData[j].id
                  res.data[i].purchase_order_id = this.checkData[j].purchase_order_id
                }
                res.data[i].unit_price = this.checkData[j].unit_price
                res.data[i].product_name = this.checkData[j].name
                // res.data[i].licence_code = this.checkData[j].licence_code
                res.data[i].factory_name = this.checkData[j].factory_name
              }
              if (res.data[i].model_name === this.checkData[j].model_name) {
                // 字段匹配
                // 备案凭证有效期至
                res.data[i].licence_valid_period = this.checkData[j].licence_valid_period ? this.$moment.unix(this.checkData[j].licence_valid_period).format('YYYY-MM-DD') : null
                // 注册证，备案凭证号
                res.data[i].licence_code = this.checkData[j].licence_code
                // 供应商名称
                res.data[i].supplier_name = this.checkData[j].supplier_name
                // 生产厂家
                res.data[i].factory_name = this.checkData[j].factory_name
                // 生产备案凭证
                res.data[i].filing_number = this.checkData[j].filing_number
                // 生产地址
                res.data[i].product_production_address = this.checkData[j].product_production_address
                // 生产许可证
                res.data[i].license_number = this.checkData[j].license_number
                // 生产许可证有效期
                res.data[i].production_license_valid_period = this.checkData[j].production_license_valid_period ? this.$moment.unix(this.checkData[j].production_license_valid_period).format('YYYY-MM-DD') : null
                // 生产备案日期
                res.data[i].production_record_date = this.checkData[j].production_record_date ? this.$moment.unix(this.checkData[j].production_record_date).format('YYYY-MM-DD') : ''
                // 经营许可证
                res.data[i].operating_license_code = this.checkData[j].operating_license_code
                // 经营许可证有效期
                if (this.checkData[j].business_license_valid_period && this.$moment.unix(this.checkData[j].business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
                  res.data[i].business_license_valid_period = this.checkData[j].business_license_valid_period ? this.$moment.unix(this.checkData[j].business_license_valid_period).format('YYYY-MM-DD') : ''
                } else {
                  res.data[i].business_license_valid_period = '长期'
                }
                // 经营备案凭证
                res.data[i].registration_license_code = this.checkData[j].registration_license_code
                // 经营备案凭证日期
                if (this.checkData[j].record_valid_period && this.$moment.unix(this.checkData[j].record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
                  res.data[i].record_valid_period = this.checkData[j].record_valid_period ? this.$moment.unix(this.checkData[j].record_valid_period).format('YYYY-MM-DD') : ''
                } else {
                  res.data[i].record_valid_period = '长期'
                }
              }
            }
          }
          let arr = []
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < this.listData.length; j++) {
              if (res.data[i].name === this.listData[j].name && res.data[i].model_name === this.listData[j].model_name && !this.listData[j].hadAdd && !res.data[i].hadAdd) {
                this.$set(this.listData[j], 'hadAdd', true)
                res.data[i].hadAdd = true

                this.listData[j].bar_code = res.data[i].bar_code
                this.listData[j].batch_number = res.data[i].batch_number
                this.listData[j].production_date = res.data[i].production_date ? res.data[i].production_date : this.listData[j].production_date
                this.listData[j].valid_period = res.data[i].valid_period ? res.data[i].valid_period : this.listData[j].valid_period
                this.listData[j].serial_number = res.data[i].serial_number
              } else if (res.data[i].name === this.listData[j].name && res.data[i].model_name === this.listData[j].model_name && !this.listData[j].hadAdd && !res.data[i].hadAdd && this.listData[j].serial_number != res.data[i].serial_number) {
                let row = res.data[i]
                res.data[i].quantity = 1
                this.$set(this.listData[j], 'hadAdd', true)
                res.data[i].hadAdd = true
                arr.push(row)
                for (let i = 0; i < this.checkData.length; i++) {
                  if (this.checkData[i].product_name == row.name && this.checkData[i].model_name == row.model_name) {
                    this.$set(this.checkData[i], 'pickNum', this.checkData[i].pickNum + 1)
                  }
                }
              } else if (res.data[i].name === this.listData[j].name && res.data[i].model_name === this.listData[j].model_name && this.listData[j].serial_number == res.data[i].serial_number) {
                res.data[i].hadAdd = true
              }
            }
          }
          for (let i = 0; i < res.data.length; i++) {
            if (!res.data[i].hadAdd && nameArr.includes(res.data[i].product_name + res.data[i].model_name)) {
              let row = res.data[i]
              res.data[i].quantity = 1
              res.data[i].hadAdd = true
              arr.push(row)
              for (let i = 0; i < this.checkData.length; i++) {
                if (this.checkData[i].product_name == row.name && this.checkData[i].model_name == row.model_name) {
                  this.$set(this.checkData[i], 'pickNum', this.checkData[i].pickNum + 1)
                }
              }
            }
          }

          // for (let i = 0; i < res.data.length; i++) {
          //   for (let j = 0; j < this.checkData.length; j++) {

          //   }
          // }
          for (let m = 0; m < arr.length; m++) {
            arr[m].isCode = true
          }
          this.listData = [...this.listData, ...arr]
          this.listData = JSON.parse(JSON.stringify(this.listData))
          this.$Message.success('数据匹配完成')
        }
      })
    },
    // 确认选择 关闭弹窗
    sureChoose() {
      if (this.hadSelectList.length == 0) {
        this.$Message.warning('请至少选择一个产品再提交')
        return
      }
      if (!this.purchase && !this.salesReturn && this.listData.length > 0) {
        for (let i = 0; i < this.hadSelectList.length; i++) {
          this.hadSelectList[i].valid_period = this.hadSelectList[i].valid_period ? this.$moment(this.hadSelectList[i].valid_period * 1000).format('YYYY-MM-DD') : ''
          this.hadSelectList[i].production_date = this.hadSelectList[i].production_date ? this.$moment(this.hadSelectList[i].production_date * 1000).format('YYYY-MM-DD') : ''
        }
        let select = this.listData.map(item => item.product_id + item.specification_id)
        this.hadSelectList = this.hadSelectList.filter(i => {
          return !select.includes(i.product_id + i.specification_id)
        })
        let arr = [...this.listData, ...this.hadSelectList]
        this.listData = JSON.parse(JSON.stringify(arr))
      } else {
        if (this.purchase) {
          for (let i = 0; i < this.hadSelectList.length; i++) {
            if (this.hadSelectList[i].delivery_line.length > 0) {
              this.listData = [...this.listData, ...this.hadSelectList[i].delivery_line]
            } else {
              this.listData = [...this.listData, this.hadSelectList[i]]
            }
          }
        } else {
          this.listData = [...this.hadSelectList]
        }
        for (let i = 0; i < this.listData.length; i++) {
          let num = this.listData[i].quantity ? this.listData[i].quantity : 0
          this.$set(this.listData[i], 'quantity', num)
          this.listData[i].valid_period = this.listData[i].valid_period ? this.$moment(this.listData[i].valid_period * 1000).format('YYYY-MM-DD') : ''
          this.listData[i].production_date = this.listData[i].production_date ? this.$moment(this.listData[i].production_date * 1000).format('YYYY-MM-DD') : ''
        }
      }
      this.chooseStatus = false
      this.purchase = false
      this.salesReturn = false
    },
    // 选择数据发生改变时
    selectChange(list) {
      this.hadSelectList = list
    },
    // 确认删除
    sureDelete() {
      this.listData.splice(this.deleteIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
      for (let i = 0; i < this.checkData.length; i++) {
        if (this.checkData[i].product_name == this.deleteObj.name && this.checkData[i].model_name == this.deleteObj.model_name) {
          this.$set(this.checkData[i], 'pickNum', this.checkData[i].pickNum - this.deleteObj.quantity)
        }
      }
    },
    // 删除
    deleteRow(row, index) {
      this.deleteObj = row
      this.deleteIndex = index
      this.deleteStatus = true
    },
    // 复制新增
    addRow(row, index) {
      // this.$set(this.listData, index + 1, row)
      this.listData.splice(index + 1, 0, row)
      this.listData = JSON.parse(JSON.stringify(this.listData))
    },
    // 确认提交
    surePost() {
      if (this.listData.length == 0) {
        this.$Message.warning('请至少添加一组产品再提交')
        return null
      }
      if (!this.queryFrom.warehouse_id) {
        this.$Message.warning('请选择收货仓库')
        return null
      }
      for (let i = 0; i < this.listData.length; i++) {
        console.log(i, 123)
        this.listData[i].delivery_number = this.listData[i].delivery_number || this.listData[i].quantity
        this.listData[i].product_name = this.listData[i].name ? this.listData[i].name : null
        this.listData[i].production_date = this.listData[i].production_date ? this.listData[i].production_date : null
        this.listData[i].valid_period = this.listData[i].valid_period ? this.listData[i].valid_period : null
        if (!this.listData[i].quantity || !this.listData[i].batch_number || !this.listData[i].valid_period) {
          this.$Message.warning(`请完善第${i + 1}组产品的批号/数量/有效期再提交`)
          return
        }
        let resCode = this.$utils.dataType(this.listData[i].valid_period)
        if (resCode.code) {
          this.$Message.warning(resCode.text)
          return
        }
        if (this.listData[i].production_date) {
          let resCode2 = this.$utils.dataType(this.listData[i].production_date)
          if (resCode2.code) {
            this.$Message.warning(resCode2.text)
            return
          }
        }
        for (let i = 0; i < this.checkData.length; i++) {
          if (this.checkData[i].pickNum > this.checkData[i].quantity) {
            this.$Message.warning(`收货数量大于订单数量`)
            return
          }
        }
      }
      for (let i = 0; i < this.checkData.length; i++) {
        if (this.checkData[i].pickNum < this.checkData[i].quantity) {
          this.modal1 = true
          return
        }
      }
      this.postStatus = true
    },
    // 最终提交
    lastPost() {
      if (!this.flag) return
      this.flag = false
      this.postFrom.warehouse_id = this.queryFrom.warehouse_id
      this.postFrom.line_info = this.listData
      this.postFrom.line_info.forEach((item, index) => {
        if (!item.licence_valid_period) {
          item.licence_valid_period = null
        }
        if (!item.production_date) {
          item.production_date = null
        }
      })
      this.$http
        .post(this.$api.acceptanceManage, this.postFrom, false)
        .then(res => {
          this.flag = true
          this.$Message.success('收货验收成功')
          this.reload()
          this.listData = []
          this.postStatus = false
          this.postFrom.note = ''
        })
        .catch(err => {
          this.flag = true
        })
      this.modal1 = false
    },
    // 查询关联采购单列表
    queeryModal1() {
      this.$http.get(this.$api.getPurchaseOrder, this.queryModal1, false).then(res => {
        this.purchaseData = res.data
        this.total = res.total
        this.purchase = true
        for (let i = 0; i < this.purchaseData.length; i++) {
          this.purchaseData[i].insertTime = this.$moment(this.purchaseData[i].insert_time * 1000).format('YYYY-MM-DD HH:mm:ss')
        }
      })
    },
    // 确认采购单
    choosePurchase(row) {
      this.$http
        .get(this.$api.pruchaseOrderDetail, { id: row.id, purchase_order_detail: '1', picking_page: '1', accept_page: '1' }, false)
        .then(res => {
          // this.chooseStatus = true
          this.postFrom.supplier_id = res.data.supplier_id
          let supplier_name = res.data.supplier_name
          this.supplier_name = res.data.supplier_name
          let arr = res.data.purchase_order_line
          for (let i = 0; i < arr.length; i++) {
            arr[i].supplier_name = supplier_name
            arr[i].name = arr[i].product_name
            arr[i].bar_code = arr[i].barcode
            arr[i].batch_number = arr[i].batch_no
            arr[i].quantity = arr[i].number
            arr[i].pickNum = arr[i].total_delivery_number
            if (arr[i].delivery_line.length > 0) {
              for (let j = 0; j < arr[i].delivery_line.length; j++) {
                arr[i].delivery_line[j].name = arr[i].delivery_line[j].product_name
                arr[i].delivery_line[j].bar_code = arr[i].delivery_line[j].barcode
                arr[i].delivery_line[j].batch_number = arr[i].delivery_line[j].batch_no
                arr[i].delivery_line[j].quantity = arr[i].delivery_line[j].number
                arr[i].delivery_line[j].pickNum = arr[i].delivery_line[j].total_delivery_number
              }
            }
          }
          // this.chooseStatus = true
          // this.chooseList = JSON.parse(JSON.stringify(arr))
          this.listData = []
          this.checkData = JSON.parse(JSON.stringify(arr))
          // this.listData = [...this.listData, ...arr]
          this.purchase = false
        })
        .then(() => {
          this.option_page = '12'
          this.type = 1
          this.queryOption()
        })
    },
    // 关联销售退货通知单
    queeryModal3() {
      if (this.clientIndex >= 0) {
        this.queryModal3.customer_id = this.clientNameList[this.clientIndex].customer_id
        this.queryModal3.customer_type = this.clientNameList[this.clientIndex].customer_type
      } else {
        this.queryModal3.customer_id = ''
        this.queryModal3.customer_type = ''
      }
      console.log(this.queryModal3,'===-----');
      this.$http.get(this.$api.saleReturn, this.queryModal3, false).then(res => {
        this.salesReturnData = res.data
        this.total = res.total
        this.salesReturn = true
        for (let i = 0; i < this.salesReturnData.length; i++) {
          this.salesReturnData[i].returnTime = this.$moment(this.salesReturnData[i].return_time * 1000).format('YYYY-MM-DD HH:mm:ss')
        }
      })
    },
    // 确认退货通知单
    saleReturnSure(row) {
      this.$http
        .get(this.$api.saleReturnDetail, { return_code: row.return_code, picking_page: '1', accept_page: '1' }, false)
        .then(res => {
          let arr = res.data.return_detail
          this.postFrom.supplier_id = res.data.supplier_id ? res.data.supplier_id : null
          for (let i = 0; i < arr.length; i++) {
            arr[i].name = arr[i].product_name
            arr[i].model_name = arr[i].product_model_name
            arr[i].item_number = arr[i].product_item_number
            arr[i].unit = arr[i].product_unit
            arr[i].unit_price = arr[i].product_unit_price
            arr[i].total_delivery_number = arr[i].total_delivery_number || 0
            arr[i].quantity = arr[i].product_amount
            arr[i].pickNum = arr[i].total_delivery_number
          }
          // this.chooseStatus = true
          // this.chooseList = JSON.parse(JSON.stringify(arr))
          this.salesReturn = false
          this.listData = []
          this.checkData = JSON.parse(JSON.stringify(arr))
        })
        .then(() => {
          this.option_page = '13'
          this.type = 2
          this.queryOption()
        })
    },
    // 关联
    openModal(num) {
      this.hadSelectList = []
      if (num == 1) {
        if (this.chooseColumns.length == 8) {
          let obj = {
            title: '发货数量',
            key: 'total_delivery_number',
            align: 'center',
            width: 100,
          }
          this.chooseColumns.splice(5, 0, obj)
          let obj2 = {
            title: '收货数量',
            key: 'received_number',
            align: 'center',
            width: 100,
          }
          this.chooseColumns.splice(6, 0, obj2)
        }
      } else {
        if (this.chooseColumns.length != 8) {
          this.chooseColumns.splice(5, 2)
        }
      }
      if (num == 1) {
        this.queeryModal1()
      } else if (num == 2) {
        this.queeryModal2()
      } else if (num == 3) {
        this.queeryModal3()
      }
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.code_id = ''
      if (!e) return
      this.modelList = []
      this.queryProductCodeSelect(e)
    },
    // 获取供应商下的产品下拉选择
    queryProductSelect(id) {
      this.$http.get(this.$api.productSelect, { supplier_id: id }, false).then(res => {
        this.productList = res.data
      })
    },
    queryProductCodeSelectByName(name) {
      this.$http.get(this.$api.productShow, { product_name: name,search_type:'1' }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 获取供应商下指定产品下的规格型号
    queryProductCodeSelect(id) {
      this.$http.get(this.$api.productCodeSelect, { supplier_id: this.queryFrom.supplier_id, product_id: id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 供应商改变
    supplierChange(e) {
      this.queryFrom.product_id = ''
      if (!e) return
      this.queryProductSelect(e)
    },
    // 点击查询按钮
    query() {
      if (!this.queryFrom.supplier_id) {
        this.$Message.warning('请先选择供应商名称再查询')
        return
      }
      if (!this.queryFrom.product_id) {
        this.$Message.warning('请先选择产品名称')
        return
      }
      let query = {
        supplier_id: this.queryFrom.supplier_id,
        product_id: this.queryFrom.product_id,
        code_id_str: this.queryFrom.code_id,
      }
      this.postFrom.supplier_id = this.queryFrom.supplier_id
      this.$http.get(this.$api.productLookup, query, false).then(res => {
        if (res.data.length > 0) {
          this.hadSelectList = []
          this.chooseList = res.data
          this.chooseStatus = true
        } else {
          this.$Message.warning('查询暂无数据')
        }
      })
    },
    // 查询仓库列表
    queryStoreList() {
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 供应商名称
    querySupplierSelect() {
      this.$http.fetch(this.$api.supplierSelect, true).then(res => {
        this.supplierList = res.data
      })
    },
    // 输入框改变
    changeIpt(e, name, index) {
      this.$set(this.listData[index], name, e.target.value.trim())
    },
    changeIpt1(e, name, index, model_name, product_name, product_code_id, id) {
      this.$set(this.listData[index], name, e)
      let total = 0
      for (let i = 0; i < this.listData.length; i++) {
        if (this.listData[i].product_name == product_name && this.listData[i].model_name == model_name && this.listData[i].quantity && this.listData[i].product_code_id == product_code_id && this.listData[i].id == id) {
          total = total * 1 + this.listData[i].quantity * 1
        }
      }
      for (let i = 0; i < this.checkData.length; i++) {
        if (this.checkData[i].product_name == product_name && this.checkData[i].model_name == model_name && this.checkData[i].product_code_id == product_code_id && this.checkData[i].id == id) {
          this.$set(this.checkData[i], 'pickNum', total * 1 + this.checkData[i].total_delivery_number)
        }
      }
    },
    // 时间改变
    changeTime(e, num) {
      if (e[0] == '' && e[1] == '') {
        if (num == 1) {
          this.queryModal1.insert_time_start = null
          this.queryModal1.insert_time_end = null
        } else if (num == 3) {
          this.queryModal3.return_time_begin = null
          this.queryModal3.return_time_end = null
        }
      } else {
        if (num == 1) {
          this.queryModal1.insert_time_start = e[0]
          this.queryModal1.insert_time_end = e[1]
        } else if (num == 3) {
          this.queryModal3.return_time_begin = e[0]
          this.queryModal3.return_time_end = e[1]
        }
      }
    },
    timeChange(e, index, name) {
      if (name == 'production_date') {
        this.$set(this.listData[index], name, e)
        let that = this
        let option = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.listData[index].production_date) - 86400000
          },
        }
        // that.$set(this.listData[index], 'options', option)
      } else {
        this.$set(this.listData[index], name, e)
      }
    },
    cancelQueryModal1(){
      this.modelList = []
      this.queryModal1.product_name = ''
      this.queryModal1.model_name = ''
    },
    // 产品名称改变
    productChange1(e) {
      this.queryModal1.model_name = ''
      // if (!e) return
      this.modelList = []
      this.queryProductCodeSelectByName(e)
    },
    cancelQueryModal3(){
      this.modelList = []
      this.queryModal3.product_name = ''
      this.queryModal3.model_name = ''
    },
    // 产品名称改变
    productChange3(e) {
      this.queryModal3.model_name = ''
      this.queryModal3.product_name =e;
      // if (!e) return
      this.modelList = []
      this.queryProductCodeSelectByName(e)
    },
    // 在弹窗里面点击查询
    queryData(num) {
      if (num == 1) {
        this.queryModal1.page = 1
        this.queeryModal1()
      } else if (num == 3) {
        this.queryModal3.page = 1
        this.queeryModal3()
      }
    },
    changePage(e, num) {
      if (num == 1) {
        this.queryModal1.page = e
        this.queeryModal1()
      } else if (num == 3) {
        this.queryModal3.page = e
        this.queeryModal3()
      }
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = !rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    },
  },
}
</script>

<style lang="less" scoped>
.checkList {
  margin-bottom: 30px;
  .picking {
    text-align: center;
    color: #ccc;
  }
}
.page {
  display: flex;
  flex-direction: column;
  color: #000;
  .top {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 22px 0;
  }

  .btm {
    flex: 1;
    display: flex;
    .btmRight {
      flex: 1;
      padding: 23px;
      border-top: 1px solid #e8eaec;
      display: flex;
      flex-direction: column;
      min-height: 400px;
      overflow: auto;
      p {
        margin-bottom: 23px;
        font-size: 18px;
        color: #525b6d;
      }
      .button {
        height: 36px;
        line-height: 36px;
        text-align: right;
        margin-top: 20px;
      }
    }
  }
}

.modal {
  /deep/ .ivu-modal-footer {
    border: 0;
  }

  .body {
    .item {
      width: 240px;
      font-size: 16px;
      color: #000000;
    }

    .iviewIptWidth250 {
      width: 150px !important;
    }
  }
}
.marginRight60 {
  margin-right: 60px;
}
.iviewIptWidth150 {
  width: 150px !important;
}
.iviewIptWidth200 {
  width: 200px !important;
}
.marginRight23 {
  margin-right: 15px;
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
/deep/ .modalClass {
  /deep/ .ivu-modal {
    width: 1050px !important;
  }
}
.totalNumber {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 0px;
  padding: 0px 30px;
  box-sizing: border-box;
  font-size: 14px;
  .item {
    margin: 0px 15px;
    span {
      // color
    }
  }
}
</style>
